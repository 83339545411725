import { default as aboutzxNCiU781iMeta } from "/vercel/path0/www/pages/about.vue?macro=true";
import { default as _91_46_46_46slug_93JRXJVrreLdMeta } from "/vercel/path0/www/pages/automation/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93SvtrCKJmfGMeta } from "/vercel/path0/www/pages/automation/category/[...slug].vue?macro=true";
import { default as how_45bookkeeping_45automation_45with_45ai_45is_45transforming_45financial_45managementlfSAyyJrdkMeta } from "/vercel/path0/www/pages/automation/faq/bookkeeping/how-bookkeeping-automation-with-ai-is-transforming-financial-management.vue?macro=true";
import { default as how_45bookkeeping_45process_45automation_45revolutionizes_45business_45operationswRxA8tJeDvMeta } from "/vercel/path0/www/pages/automation/faq/bookkeeping/how-bookkeeping-process-automation-revolutionizes-business-operations.vue?macro=true";
import { default as unlock_45the_45power_45of_45xero_45bookkeeping_45automation_45for_45your_45business_45successXI4LWFK63jMeta } from "/vercel/path0/www/pages/automation/faq/bookkeeping/unlock-the-power-of-xero-bookkeeping-automation-for-your-business-success.vue?macro=true";
import { default as what_45is_45automated_45bookkeeping_45and_45how_45does_45it_45workd0NgVw55BJMeta } from "/vercel/path0/www/pages/automation/faq/bookkeeping/what-is-automated-bookkeeping-and-how-does-it-work.vue?macro=true";
import { default as how_45to_45conduct_45automation_45testing_45on_45the_45ecommerceClv6MkFgM5Meta } from "/vercel/path0/www/pages/automation/faq/ecommerce/how-to-conduct-automation-testing-on-the-ecommerce.vue?macro=true";
import { default as how_45to_45use_45marketing_45automation_45for_45ecommerceUjUwicHtIWMeta } from "/vercel/path0/www/pages/automation/faq/ecommerce/how-to-use-marketing-automation-for-ecommerce.vue?macro=true";
import { default as what_45is_45ecommerce_45automationvpXiKi29q3Meta } from "/vercel/path0/www/pages/automation/faq/ecommerce/what-is-ecommerce-automation.vue?macro=true";
import { default as why_45you_45need_45automation_45testing_45services_45for_45your_45ecommerce_45businessY5QbPgUY4zMeta } from "/vercel/path0/www/pages/automation/faq/ecommerce/why-you-need-automation-testing-services-for-your-ecommerce-business.vue?macro=true";
import { default as indexFYebX9UXg9Meta } from "/vercel/path0/www/pages/automation/index.vue?macro=true";
import { default as _91_46_46_46slug_93Dbn2L8dQlKMeta } from "/vercel/path0/www/pages/automation/tool/[...slug].vue?macro=true";
import { default as automationsRk7EYGunBdMeta } from "/vercel/path0/www/pages/automations.vue?macro=true";
import { default as contacteOZ3sk2VFDMeta } from "/vercel/path0/www/pages/contact.vue?macro=true";
import { default as feedbackHfztun4zidMeta } from "/vercel/path0/www/pages/feedback.vue?macro=true";
import { default as automationY5ISD0ibnFMeta } from "/vercel/path0/www/pages/forms/automation.vue?macro=true";
import { default as new_45automationsOE0a7sGNnMeta } from "/vercel/path0/www/pages/forms/new-automation.vue?macro=true";
import { default as new_45clientHcfzQz0U9UMeta } from "/vercel/path0/www/pages/forms/new-client.vue?macro=true";
import { default as indexyBKpczxIcoMeta } from "/vercel/path0/www/pages/index.vue?macro=true";
import { default as processgaf7CtI6bZMeta } from "/vercel/path0/www/pages/process.vue?macro=true";
import { default as rapidi29DAtVptnMeta } from "/vercel/path0/www/pages/rapid.vue?macro=true";
import { default as index5oySoTYqYjMeta } from "/vercel/path0/www/pages/tools/index.vue?macro=true";
import { default as llanayEbbku6ipNMeta } from "/vercel/path0/www/pages/tools/llana.vue?macro=true";
import { default as uspl92TVDE8fXMeta } from "/vercel/path0/www/pages/usp.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    component: () => import("/vercel/path0/www/pages/about.vue")
  },
  {
    name: "automation-slug",
    path: "/automation/:slug(.*)*",
    meta: _91_46_46_46slug_93JRXJVrreLdMeta || {},
    component: () => import("/vercel/path0/www/pages/automation/[...slug].vue")
  },
  {
    name: "automation-category-slug",
    path: "/automation/category/:slug(.*)*",
    component: () => import("/vercel/path0/www/pages/automation/category/[...slug].vue")
  },
  {
    name: "automation-faq-bookkeeping-how-bookkeeping-automation-with-ai-is-transforming-financial-management",
    path: "/automation/faq/bookkeeping/how-bookkeeping-automation-with-ai-is-transforming-financial-management",
    component: () => import("/vercel/path0/www/pages/automation/faq/bookkeeping/how-bookkeeping-automation-with-ai-is-transforming-financial-management.vue")
  },
  {
    name: "automation-faq-bookkeeping-how-bookkeeping-process-automation-revolutionizes-business-operations",
    path: "/automation/faq/bookkeeping/how-bookkeeping-process-automation-revolutionizes-business-operations",
    component: () => import("/vercel/path0/www/pages/automation/faq/bookkeeping/how-bookkeeping-process-automation-revolutionizes-business-operations.vue")
  },
  {
    name: "automation-faq-bookkeeping-unlock-the-power-of-xero-bookkeeping-automation-for-your-business-success",
    path: "/automation/faq/bookkeeping/unlock-the-power-of-xero-bookkeeping-automation-for-your-business-success",
    component: () => import("/vercel/path0/www/pages/automation/faq/bookkeeping/unlock-the-power-of-xero-bookkeeping-automation-for-your-business-success.vue")
  },
  {
    name: "automation-faq-bookkeeping-what-is-automated-bookkeeping-and-how-does-it-work",
    path: "/automation/faq/bookkeeping/what-is-automated-bookkeeping-and-how-does-it-work",
    component: () => import("/vercel/path0/www/pages/automation/faq/bookkeeping/what-is-automated-bookkeeping-and-how-does-it-work.vue")
  },
  {
    name: "automation-faq-ecommerce-how-to-conduct-automation-testing-on-the-ecommerce",
    path: "/automation/faq/ecommerce/how-to-conduct-automation-testing-on-the-ecommerce",
    component: () => import("/vercel/path0/www/pages/automation/faq/ecommerce/how-to-conduct-automation-testing-on-the-ecommerce.vue")
  },
  {
    name: "automation-faq-ecommerce-how-to-use-marketing-automation-for-ecommerce",
    path: "/automation/faq/ecommerce/how-to-use-marketing-automation-for-ecommerce",
    component: () => import("/vercel/path0/www/pages/automation/faq/ecommerce/how-to-use-marketing-automation-for-ecommerce.vue")
  },
  {
    name: "automation-faq-ecommerce-what-is-ecommerce-automation",
    path: "/automation/faq/ecommerce/what-is-ecommerce-automation",
    component: () => import("/vercel/path0/www/pages/automation/faq/ecommerce/what-is-ecommerce-automation.vue")
  },
  {
    name: "automation-faq-ecommerce-why-you-need-automation-testing-services-for-your-ecommerce-business",
    path: "/automation/faq/ecommerce/why-you-need-automation-testing-services-for-your-ecommerce-business",
    component: () => import("/vercel/path0/www/pages/automation/faq/ecommerce/why-you-need-automation-testing-services-for-your-ecommerce-business.vue")
  },
  {
    name: "automation",
    path: "/automation",
    component: () => import("/vercel/path0/www/pages/automation/index.vue")
  },
  {
    name: "automation-tool-slug",
    path: "/automation/tool/:slug(.*)*",
    component: () => import("/vercel/path0/www/pages/automation/tool/[...slug].vue")
  },
  {
    name: "automations",
    path: "/automations",
    component: () => import("/vercel/path0/www/pages/automations.vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/vercel/path0/www/pages/contact.vue")
  },
  {
    name: "feedback",
    path: "/feedback",
    component: () => import("/vercel/path0/www/pages/feedback.vue")
  },
  {
    name: "forms-automation",
    path: "/forms/automation",
    component: () => import("/vercel/path0/www/pages/forms/automation.vue")
  },
  {
    name: "forms-new-automation",
    path: "/forms/new-automation",
    component: () => import("/vercel/path0/www/pages/forms/new-automation.vue")
  },
  {
    name: "forms-new-client",
    path: "/forms/new-client",
    component: () => import("/vercel/path0/www/pages/forms/new-client.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/www/pages/index.vue")
  },
  {
    name: "process",
    path: "/process",
    component: () => import("/vercel/path0/www/pages/process.vue")
  },
  {
    name: "rapid",
    path: "/rapid",
    component: () => import("/vercel/path0/www/pages/rapid.vue")
  },
  {
    name: "tools",
    path: "/tools",
    component: () => import("/vercel/path0/www/pages/tools/index.vue")
  },
  {
    name: "tools-llana",
    path: "/tools/llana",
    component: () => import("/vercel/path0/www/pages/tools/llana.vue")
  },
  {
    name: "usp",
    path: "/usp",
    component: () => import("/vercel/path0/www/pages/usp.vue")
  }
]